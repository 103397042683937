import { ChainId, Token, WETH } from '@pancakeswap/sdk'

export const MAIN_TOKEN = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x8efda34C7417071b8dA1239Aa2F4033a223B5A34',
    18,
    'Gmining',
    'GMNT',
    'https://hengcoin.com/'
  ),

  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x9053f78EA849aA49505ed40B5C480e1d883eA7DB',
    18,
    'Gmining',
    'GMNT',
    'https://hengcoin.com/'
  )

}

export const USDT = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'USDT',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
    18,
    'USDT',
    'Tether USD',
    'https://www.centre.io/usdt'
  ),
}

export const BUSD = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'BUSD',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'BUSD',
    'https://www.binance.com/',
  ),
}

export const WBNB = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
}

export const MAIN_TOKEN2 = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    18,
    'USDC',
    'USD Coin',
    'https://mms-soilminerals.com/'
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xF07eaa9481b8A522f3F248ad2A3797Bcf6315085',
    8,
    'MMSC',
    'MMS COIN',
    'https://mms-soilminerals.com/'
  ),
}

export const MAIN_TOKEN3 = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    18,
    'BTCB',
    'BTCB Token',
    'https://mms-soilminerals.com/'
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x7B3E14386463A18aA9Ca8Ea3dAF534313F3695EE',
    18,
    'MALI',
    'MALIRICE',
    'https://mms-soilminerals.com/'
  ),
}

export const MAIN_TOKEN4 = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x807e24398591DC7AaB77E2E19dAcC40Ac6d18dF5',
    18,
    'KOM',
    'King Of Money',
    'https://mms-soilminerals.com/'
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xFF0E9946D0b596835C2Acc41eb7FDef30579D6fF',
    18,
    'BKR',
    'BKR',
    'https://mms-soilminerals.com/'
  ),
}

export const SWAP = {
  [ChainId.BSC]: MAIN_TOKEN[ChainId.BSC],
  [ChainId.BSC_TESTNET]: MAIN_TOKEN[ChainId.BSC_TESTNET],
}

export const SWAP2 = {
  [ChainId.BSC]: USDT[ChainId.BSC],
  [ChainId.BSC_TESTNET]: USDT[ChainId.BSC_TESTNET],
}



export const bscTokens = {
  wbnb: WETH[ChainId.BSC],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: WBNB[ChainId.BSC],
  busd: BUSD[ChainId.BSC],
  usdt: USDT[ChainId.BSC],
  bkr: MAIN_TOKEN2[ChainId.BSC],
  cake: MAIN_TOKEN[ChainId.BSC],
  mali: MAIN_TOKEN3[ChainId.BSC],
  cbn: MAIN_TOKEN4[ChainId.BSC]
}

export const bscTestnetTokens = {
  wbnb: WETH[ChainId.BSC_TESTNET],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: WBNB[ChainId.BSC_TESTNET],
  busd: BUSD[ChainId.BSC_TESTNET],
  usdt: USDT[ChainId.BSC_TESTNET],
  bkr: MAIN_TOKEN2[ChainId.BSC_TESTNET],
  cake: MAIN_TOKEN[ChainId.BSC_TESTNET],
  mali: MAIN_TOKEN3[ChainId.BSC_TESTNET],
  cbn: MAIN_TOKEN4[ChainId.BSC_TESTNET]
}

