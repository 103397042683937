/* eslint-disable */
import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  EarnIcon,
  TrophyFillIcon,
  MoreIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { DropdownMenuItemType } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Swap'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: t('Pools'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/liquidity',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: 'Earn',
        href: 'https://gmnt.org/stakev3/',
        icon: EarnIcon,
        type: DropdownMenuItemType.EXTERNAL_LINK,
        showItemsOnMobile: false,
        items: []
      },
      {
        label: "",
        href: "#",
        icon: MoreIcon,
        items: [
          {
            label: "NFTs",
            href: "#",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Marketplace",
            href: "#",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Crypto Bank (GMNT wallet)",
            href: "#",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Payment gateway",
            href: "#",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Debit card",
            href: "#",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "GMNT Coin",
            href: "https://gmnt.org/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Document",
            href: "https://docs.gmnt.org/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Coinmarketcap",
            href: "https://coinmarketcap.com/currencies/gmining/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ],
      }
    ].filter((item) => filterItemBySupportChainId(item, chainId))

export default config
